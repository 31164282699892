<template>
  <div class="content">
    <div class="lp-public-content">
      <div class="q-pa-md row justify-center">
        <div class="full-width" style="max-width: 1200px">
          <div v-if="!in_request">
            <carousel-banner :banners="Object.values(lp.carousel || {})" />
          </div>
          <q-skeleton v-else width="100%" height="350px" />
        </div>
      </div>
      <h4
        v-if="lp.params && lp.params.landing_page_prods_title"
        class="text-h4 text-uppercase text-weight-regular text-grey-7 q-mb-none text-center"
        v-html="lp.params.landing_page_prods_title.value"
      />
      <products-skeleton v-if="in_request"></products-skeleton>
      <products v-if="!in_request" :products="lp.products" />
    </div>
  </div>
</template>

<script>
import CarouselBanner from "@/modules/main/pages/landing-pages/components/carouselBanner";
import ProductsSkeleton from "@/modules/main/pages/products/components/productsSkeleton";
import Products from "@/modules/main/pages/products/components/products";
import { createNamespacedHelpers } from "vuex-composition-helpers";
import { useRoute } from "vue-router";
import { ref } from "vue";

const { useGetters, useActions } = createNamespacedHelpers("landing_page");

export default {
  name: "LpProducts",

  components: {
    CarouselBanner,
    ProductsSkeleton,
    Products,
  },

  setup() {
    const route = useRoute();

    const { lp } = useGetters({
        lp: "getLpProducts",
      }),
      { fetchLpProductsPublic } = useActions(["fetchLpProductsPublic"]),
      in_request = ref(true);

    fetchLpProductsPublic({
      params: {
        slug: route.params.slug,
        user_slug: route.params.user_slug,
      },
    }).finally(() => (in_request.value = false));

    return {
      in_request,
      lp,
    };
  },
};
</script>

<style lang="scss">
body {
  background-color: #f7f7f7;
}
.content {
  height: 100%;
  .lp-public-content {
    max-width: 1200px;
    margin: 50px auto;

    .cursor-pointer.favorite {
      display: none;
    }
  }
}
.mobile {
  .content {
    .lp-public-content {
      margin: 30px;
    }
  }
}
</style>
